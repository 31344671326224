@import "xterm/css/xterm.css";
* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
  font-size: calc(15px + 0.390625vw);
  font-family: Verdana, serif;
}

#terminal {
  height: 100vh;
  width: 100vw;
  .terminal {
    height: 100%;
    width: 100%;
    .xterm-viewport {
      height: 100%;
      width: 100%;
    }
    .xterm-screen {
      width: 100%;
      height: 100%;
    }
  }
}